import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Row, Col, Card, Spin, List, Tabs, Button, message, Checkbox } from 'antd';
import Feedback from './components/Feedback';
import FeedbackResult from './components/FeedbackResult';
import { useHistory } from 'react-router-dom';
import { auditService } from '../_services/audit.service';
import moment from 'moment';

const { TabPane } = Tabs;

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
}

const AuditPage = () => {
  const history = useHistory();
  const { auditId, consultationId } = useParams();
  const [consultation, setConsultation] = useState();
  const [auditConsultation, setAuditConsultation] = useState();
  const [auditHeader, setAuditHeader] = useState();
  const [exams, setExams] = useState();
  const [loading, setLoading] = useState(false);
  const [loadingExams, setLoadingExams] = useState(false);
  const [loadingNext, setLoadingNext] = useState(false);
  const [loadingFinish, setLoadingFinish] = useState(false);
  let query = useQuery();

  const fetchConsultation = async () => {
    setLoading(true);

    try {
      const response = await auditService.consultation({ auditId, consultationId });
      setConsultation(response.data.consultation);
      setAuditConsultation(response.data.audit_consultation);
      setAuditHeader(response.data.auditHeader);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  const fetchExams = async () => {
    if (consultation) {
      setLoadingExams(true);
      const response = await auditService.exams({
        consultationId: consultation.consultation_id,
        admissionPatientId: consultation.admission_patient_id
      });
      setExams(response);
      setLoadingExams(false);
    };
  }

  useEffect(() => {
    fetchConsultation();
  }, [])

  useEffect(() => {
    fetchExams();
  }, [consultation])

  const showFile = async (admissionProcedureResultId) => {
    const response = await auditService.getProcedureResultFile({ admissionProcedureResultId });
    window.open(response.data.url, '_blank');
  }

  const showDocumentFile = async (id) => {
    const response = await auditService.getDocumentFile({ objectId: id, objectType: 'ExamsDocument', objectField: 'document'});
    window.open(response.data.document_url, '_blank');
  }  

  const nextConsultation = () => {
    setLoadingNext(true);
    const params = {
      auditConsultationId: consultationId,
      auditSessionId: auditId
    }

    auditService.create(params)
      .then(response => {
        window.location.href = `/auditoria/${response.audit_session_id}/${response.id}`;
      }).catch(err => {
        message.error('Ha ocurrido un error al iniciar el proceso de auditoria');
      }).finally(() => {
        setLoadingNext(false);
      });
  }

  const finishAudit = () => {
    setLoadingFinish(true);
    const params = {
      auditConsultationId: consultationId,
      auditSessionId: auditId
    }

    auditService.finish(params)
      .then(response => {
        window.location.href = `/`;
      }).catch(err => {
        message.error('Ha ocurrido un error al iniciar el proceso de auditoria');
      }).finally(() => {
        setLoadingFinish(false);
      });
  }

  const approvedStyle = (consultation) => {
    const color = consultation.approved ? '#2F9A81' : '#e74c3c';

    return {
      color: color,
      border: '4px solid',
      borderColor: color,
      padding: 20,
      fontFamily: 'MarkPro',
      fontWeight: 900,
      width: '60%'
    }
  }

  const calculateAge = (birthdate) => {
    return parseInt(moment().diff(birthdate, 'years', true));
  }

  const isAFile = (admissionProcedureResult) => {   
    return admissionProcedureResult.object_name == 'archivo' || admissionProcedureResult.object_name == 'Archivo';
  }

  if (loading) {
    return (
      <Spin />
    )
  }

  return (
    <React.Fragment>
      {
        consultation && <Row>
          <Col lg={24}>
            <div style={{ float: 'left' }}>
              <h1>{consultation.battery.name}</h1>
            </div>
            {
              !query.get("view") && <div style={{ float: 'right' }}>
                <Button loading={loadingFinish} style={{ marginRight: 20 }} type="primary" size="large" onClick={finishAudit}>Finalizar</Button>
                <Button loading={loadingNext} type="primary" size="large" onClick={nextConsultation}>Siguiente</Button>
              </div>}
          </Col>

          <Col lg={24}>
            <Card title="Paciente">
              <Row gutter={40}>
                <Col lg={12}>
                  <List>
                    <List.Item>
                      {
                        consultation.admission_patient.patient.rut ? (
                          <React.Fragment>
                            RUT: {consultation.admission_patient.patient.rut}
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            Pasaporte: {consultation.admission_patient.patient.passport}
                          </React.Fragment>
                        )
                      }
                    </List.Item>
                    <List.Item>
                      Edad: {calculateAge(consultation.admission_patient.patient.birthdate)}
                    </List.Item>
                    <List.Item>
                      Género: {consultation.admission_patient.patient.gender == "0" ? "Masculino" : "Femenino"}
                    </List.Item>
                    <List.Item>
                      Profesión: {consultation.admission_patient.patient.profession}
                    </List.Item>
                  </List>
                </Col>
                <Col lg={12}>
                  <h2>
                    Resultado de evaluación
                    <div style={approvedStyle(consultation)}>
                      {consultation.approved ? 'PACIENTE APROBADO' : 'PACIENTE REPROBADO'}
                    </div>
                    <FeedbackResult auditId={auditId}
                      consultationId={consultationId}
                      auditConsultation={auditConsultation} />
                  </h2>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col lg={24} style={{ marginTop: 40 }}>
            <Card title="Anamnesis">
              <Row gutter={20}>
                <Col lg={12}>
                  {consultation.comment}
                </Col>
                <Col lg={12}>
                  <Feedback auditId={auditId}
                    consultationId={consultationId}
                    auditableId={null}
                    auditableType="comment"
                    view={query.get("view")}
                    auditConsultation={auditConsultation} />
                </Col>
              </Row>
            </Card>
          </Col>
          <Col lg={24} style={{ marginTop: 40 }}>
            <Card title="Exámen Físico">
              <Row gutter={20}>
                <Col lg={12}>
                  {consultation.detail}
                </Col>
                <Col lg={12}>
                  <Feedback auditId={auditId}
                    consultationId={consultationId}
                    auditableId={null}
                    auditableType="detail"
                    view={query.get("view")}
                    auditConsultation={auditConsultation} />
                </Col>
              </Row>
            </Card>
          </Col>
          <Col lg={24} style={{ marginTop: 40 }}>
            <Card title="Conclusión">
              <Row gutter={20}>
                <Col lg={12}>
                  {consultation.conclusion}
                </Col>
                <Col lg={12}>
                  <Feedback auditId={auditId}
                    consultationId={consultationId}
                    auditableId={null}
                    auditableType="conclusion"
                    view={query.get("view")}
                    auditConsultation={auditConsultation} />
                </Col>
              </Row>
            </Card>
          </Col>

          <Col lg={24} style={{ marginTop: 40 }}>
            <Card title="Examenes">
              <Row>
                <Col lg={24}>
                  <Tabs defaultActiveKey="1" tabPosition="left">
                    {
                      exams && exams.map(examItem => {
                        return (
                          <TabPane tab={examItem.name} key={examItem.exam_id}>
                            <Row gutter={40}>
                              <Col lg={12}>
                                <List>
                                  {
                                    examItem.procedure_results && examItem.procedure_results.map(admissionProcedureResult => {
                                      return (
                                        <List.Item>
                                          {
                                            <React.Fragment>
                                              <strong>{admissionProcedureResult.object_name}:</strong> {admissionProcedureResult.result}
                                              {
                                                isAFile(admissionProcedureResult) && <a href="javascript:;" onClick={() => {                                                  
                                                  showFile(admissionProcedureResult.id);
                                                }}>
                                                  mostrar archivo
                                                </a>
                                              }
                                            </React.Fragment> 
                                          }
                                        </List.Item>
                                      )
                                    })
                                  }

                                  {
                                    examItem.question_results && examItem.question_results.map(admissionQuestionResult => {
                                      return (
                                        <List.Item>
                                          <strong>{admissionQuestionResult.object_name}:</strong> {admissionQuestionResult.result}
                                        </List.Item>
                                      )
                                    })
                                  }

                                  {
                                    examItem.measurement_results && examItem.measurement_results.map(admissionMeasurementResult => {
                                      return (
                                        <List.Item>
                                          <strong>{admissionMeasurementResult.object_name}:</strong> {admissionMeasurementResult.result}
                                        </List.Item>
                                      )
                                    })
                                  }

                                  {
                                    examItem.documents && examItem.documents.map(document => {
                                      return (
                                        <List.Item>
                                          <strong>Documento {document.name}: </strong>
                                          {
                                            <a href="javascript:;" onClick={() => {                                                  
                                              showDocumentFile(document.id);
                                            }}>
                                              mostrar documento
                                            </a>
                                          }                                          
                                        </List.Item>
                                      )
                                    })
                                  }                                  
                                </List>
                              </Col>
                              <Col lg={12}>
                                <Feedback
                                  auditId={auditId}
                                  consultationId={consultationId}
                                  auditableType="exam"
                                  auditableId={examItem.exam_id}
                                  view={query.get("view")}
                                  auditConsultation={auditConsultation} />
                              </Col>
                            </Row>
                          </TabPane>
                        )
                      })
                    }
                  </Tabs>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      }
    </React.Fragment >
  )
}

export default AuditPage;