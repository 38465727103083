import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Form, Input, Spin, message } from 'antd';
import { InfoCircleFilled, EditFilled } from '@ant-design/icons';
import { auditService } from '../../_services/audit.service';

const { TextArea } = Input;

const Feedback = (
  {
    auditId,
    consultationId,
    auditableId,
    auditableType,
    view,
    auditConsultation
  }
) => {
  const [editMode, setEditMode] = useState(false);
  const [audit, setAudit] = useState();
  const [auditLoading, setAuditLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const fetchFeedback = async () => {
    const params = {
      auditId,
      consultationId,
      auditableType
    }

    if (auditableId) {
      params['auditableId'] = auditableId;
    }

    try {
      setAuditLoading(true);
      const response = await auditService.getFeedback(params);
      form.setFieldsValue({
        feedback: response.data.feedback
      });
      setAudit(response.data);
      setAuditLoading(false);
    } catch (error) {
      setAuditLoading(false);
    }
  }

  useEffect(() => {
    fetchFeedback();
  }, [])

  const handleEditMode = () => {
    setEditMode(true);
  }

  const onFinish = async (values) => {
    setLoading(true);
    const feedback = values['feedback'];
    const data = {
      auditId: auditId,
      consultationId: parseInt(consultationId),
      auditableType: auditableType,
      feedback: feedback
    }

    if (auditableId) {
      data['auditableId'] = auditableId;
    }

    auditService.saveFeedback(data).then(response => {
      message.success('Feedback guardado');
      setEditMode(false);
      setAudit({ feedback });
      setLoading(false);
    }).catch(error => {
      message.error('Ups, hemos tenido un error al guardar el feedback');
    });
  }

  return (
    <Row justify="space-between" style={{ padding: 20, boxShadow: '0px 1px 19px -14px black' }}>
      <Col>
        <h2>
          <InfoCircleFilled style={{ color: '#5B366D' }} /> Observaciones
        </h2>
        <div>
          {auditLoading && <Spin />}
          {!editMode && audit && audit.feedback}
          {
            editMode && <Form onFinish={onFinish} form={form}>
              <Form.Item name="feedback">
                <TextArea />
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit" loading={loading}>
                  Guardar
              </Button>
              </Form.Item>
            </Form>
          }
        </div>
      </Col>
      <Col>
        {
          (!editMode || !view) && auditConsultation && auditConsultation.state != 'audited' && <React.Fragment>
            <Button onClick={handleEditMode} shape="circle" icon={<EditFilled style={{ fontSize: 20, color: '#DD592A' }} />} />
          </React.Fragment>
        }
      </Col>
    </Row>
  )
}

export default Feedback;