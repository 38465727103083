import React, { useState, useEffect } from 'react';
import { Row, Col, Select, Form, DatePicker, Button, Spin } from 'antd';
import ComparativeChart from './components/ComparativeChart';
import * as moment from 'moment';
import { auditService } from '../_services/audit.service';
import { dashboardService } from '../_services/dashboard.service';
import AuditedReports from './components/AuditedReports';
import RankingAudited from './components/RankingAudited';
import { useHistory } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { message } from 'antd';
import CountByExamReport from './components/CountByExamReport';

const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';
const cookies = new Cookies();

const HomePage = () => {
    const history = useHistory();
    const [branchOffices, setBranchOffices] = useState();
    const [existingSession, setExistingSession] = useState();
    const [loading, setLoading] = useState(false);
    const [loadingExistingSession, setLoadingExistingSession] = useState(false);

    const fetchBranchOffices = async () => {
        const response = await dashboardService.getBranchOffices({});
        setBranchOffices(response.data);
    }

    const fetchExistingOpenSession = async () => {
        setLoadingExistingSession(true);
        const response = await dashboardService.getExistingOpenSession({});

        if (response.data && response.data.length) {
            setExistingSession(response.data[0]);
        }

        setLoadingExistingSession(false);
    }

    useEffect(() => {
        fetchBranchOffices();
    }, []);

    useEffect(() => {
        fetchExistingOpenSession();
    }, [])

    const createAudit = () => {
        const params = {};
        setLoading(true);
        if (existingSession) {
            params['auditSessionId'] = existingSession.audit_session_id;
        }

        auditService.create(params)
            .then(response => {
                history.push(`/auditoria/${response.audit_session_id}/${response.id}`);
            }).catch(err => {
                message.error('Ha ocurrido un error al iniciar el proceso de auditoria');
            }).finally(() => {
                setLoading(false);
            });
    }

    const isManager = () => {
        const roles = cookies.get('roles');
        return roles.includes('gerencia') && roles.includes('super_usuario');
    }

    if (loadingExistingSession) {
        return (
            <Spin />
        )
    }

    return (
        <React.Fragment>
            <Form layout="vertical" size="large">
                <Row justify="space-between">
                    {/* <Col lg={12}>
                        <Row gutter={8}>
                            <Col lg={8}>

                                <Form.Item label="Sucursal">
                                    <Select>
                                        {branchOffices && branchOffices.map(branchOffice => {
                                            return (
                                                <Select.Option
                                                    key={branchOffice.id}
                                                    value={branchOffice.id}
                                                >
                                                    {branchOffice.name}
                                                </Select.Option>
                                            )
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col lg={8}>
                                <Form.Item label="Sucursal">
                                    <RangePicker
                                        defaultValue={[moment('2015-06-06', dateFormat), moment('2015-06-06', dateFormat)]}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                    </Col> */}
                    <Col push={0}>
                        <Form.Item label=" ">
                            {
                                existingSession ? <Button loading={loading} type="primary" size="large" onClick={createAudit}>Continuar Auditoria</Button> :
                                    <Button loading={loading} type="primary" size="large" onClick={createAudit}>Comenzar Auditoria</Button>
                            }
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <Row gutter={10}>
                <Col lg={12}>
                    {branchOffices && <AuditedReports branchOffices={branchOffices} />}
                </Col>
                <Col lg={12}>
                    <CountByExamReport/>
                </Col>
                <Col lg={24}>
                    <ComparativeChart />
                </Col>
                <Col lg={12}>
                    {isManager() && <RankingAudited />}
                </Col>
            </Row>
        </React.Fragment>
    );
}

export { HomePage };