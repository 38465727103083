import React, { useEffect, useState } from 'react';
import { Card, Table } from 'antd';
import { dashboardService } from '../../_services/dashboard.service';

const columns = [
  {
    title: 'Sucursal',
    dataIndex: 'branchOffice',
    key: 'branch_office',
  },
  {
    title: 'Cantidad',
    dataIndex: 'count',
    key: 'count',
  },
]

const AuditedReports = ({ branchOffices }) => {
  const [data, setData] = useState([]);
  const [loadedData, setLoadedData] = useState(false);
  const [total, setTotal] = useState(0);


  const fetchData = async () => {


    const response = await dashboardService.getAuditedCountByBranchOffices({});
    setData(response.data.map(item => {
      const branchOfficeSelected = branchOffices.find(branchOffice => {
        return branchOffice.id == item.branch_office_id;
      });

      if (branchOfficeSelected) {
        const branchOffice = branchOfficeSelected.name;
        // const count = item.count;
        const count = <span>{ item.count }</span>

        return {
          branchOffice,
          count
        }
      }

    }));


    let t = 0;
    response.data.map(obj => t += Number(obj.count))
    setTotal([{
      "branchOffice": <strong>Total</strong>,
      "count": <strong>{t}</strong>
    }]);

  }

  useEffect(() => {
    fetchData();
  }, [])

  return (
    <Card title="Informes Auditados">
      <Table columns={columns} dataSource={data}  pagination={false} />
      <Table columns={columns} dataSource={total} pagination={false} showHeader={false}/>
    </Card>
  )
}

export default AuditedReports;