import Cookies from 'universal-cookie';
import config from '../config';

function logout() {
  const cookies = new Cookies();
  cookies.remove('user');
}

function handleResponse(response) {

  return response.text().then(text => {
    let data = {};

    try {
      data = text && JSON.parse(text);
    } catch (error) {
      console.log(error);
    }


    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
        window.location.href = config.loginRedirect;
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}

export default handleResponse;