import React, { useState, useEffect } from 'react';
import { Card, Table } from 'antd';
import { dashboardService } from '../../_services/dashboard.service';

const columns = [
  {
    title: 'Doctor',
    dataIndex: 'admin_id',
    key: 'admin_id',
  },
  {
    title: 'Reportes Auditados',
    dataIndex: 'audited',
    key: 'audited',
  },
  {
    title: 'Reportes con observaciones',
    dataIndex: 'with_feedback',
    key: 'with_feedback',
  },
]

const RankingAudited = () => {
  const [data, setData] = useState();

  const fetchData = async () => {
    const response = await dashboardService.getRankingAudited({});
    setData(response.data);
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Card title="Ranking de médicos auditados" style={{ marginTop: 40, marginBottom: 40 }}>
      {data && <Table columns={columns} dataSource={data} />}
    </Card>
  )
}

export default RankingAudited;