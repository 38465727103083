import Cookies from 'universal-cookie';

export function authHeader() {
    const cookies = new Cookies();
    let token = cookies.get('user');

    if (token) {
        return { 'Authorization': 'Bearer ' + token };
    } else {
        return {};
    }
}