import config from '../config';
import { authHeader } from '../_helpers';
import handleResponse from './handle_response';

export const dashboardService = {
  getRankingAudited,
  getAuditedCountByBranchOffices,
  getAuditedCountByDates,
  getBranchOffices,
  getExistingOpenSession,
  getCountByExamReport
};

function getRankingAudited(params) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  const url = new URL(`${config.apiUrl}/dashboard/getRankingAudited`);
  Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
  return fetch(url, requestOptions).then(handleResponse);
}

function getAuditedCountByBranchOffices(params) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  const url = new URL(`${config.apiUrl}/dashboard/getAuditedCountByBranchOffices`);
  Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
  return fetch(url, requestOptions).then(handleResponse);
}

function getAuditedCountByDates(params) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  const url = new URL(`${config.apiUrl}/dashboard/getAuditedCountByDates`);
  Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
  return fetch(url, requestOptions).then(handleResponse);
}

function getBranchOffices(params) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  const url = new URL(`${config.apiUrl}/dashboard/getBranchOffices`);
  Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
  return fetch(url, requestOptions).then(handleResponse);
}

function getExistingOpenSession(params) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  const url = new URL(`${config.apiUrl}/dashboard/getExistingOpenSession`);
  Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
  return fetch(url, requestOptions).then(handleResponse);
}


function getCountByExamReport(params) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  const url = new URL(`${config.apiUrl}/dashboard/getCountByExamReport`);
  Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
  return fetch(url, requestOptions).then(handleResponse);
}