import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Cookies from 'universal-cookie';
import PrivateLayout from './PrivateLayout';
import config from '../config';

function PrivateRoute({ component: Component, roles, ...rest }) {
    const cookies = new Cookies();

    return (
        <Route {...rest} render={props => {
            if (!cookies.get('user')) {
                window.location.href = config.loginRedirect;
                return;
            }

            // logged in so return component
            return <PrivateLayout component={Component} {...rest} />
        }} />
    );
}

export { PrivateRoute };