import React, { useEffect, useState } from 'react';
import { Checkbox, Spin, message } from 'antd';
import { auditService } from '../../_services/audit.service';

const auditableType = 'wrong_result';

const FeedbackResult = ({
  auditId,
  consultationId,
  auditConsultation
}) => {
  const [audit, setAudit] = useState();
  const [auditLoading, setAuditLoading] = useState(false);

  const fetchFeedback = async () => {

    const params = {
      auditId,
      consultationId,
      auditableType
    }

    try {
      setAuditLoading(true);
      const response = await auditService.getFeedback(params);
      setAudit(response.data);
      setAuditLoading(false);
    } catch (error) {
      setAuditLoading(false);
    }
  }

  useEffect(() => {
    fetchFeedback();
  }, [])

  const onChange = async (event) => {
    setAuditLoading(true);
    const data = {
      auditId: auditId,
      consultationId: parseInt(consultationId),
      auditableType: auditableType,
    }

    if (event.target.checked) {
      auditService.saveFeedback(data).then(response => {
        setAudit(true);
        message.success('Feedback guardado');
      }).catch(error => {
        message.error('Ups, hemos tenido un error al guardar el feedback');
      }).finally(() => {
        setAuditLoading(false);
      });

    } else {
      auditService.removeFeedback(data).then(response => {
        setAudit(false);
        message.success('Feedback guardado');
      }).catch(error => {
        message.error('Ups, hemos tenido un error al guardar el feedback');
      }).finally(() => {
        setAuditLoading(false);
      });
    }
  }

  if (auditLoading) {
    return (
      <Spin />
    )
  }

  return (
    <div>
      {
        auditConsultation && auditConsultation.state == 'audited' ? <div>
          {
            audit ? 'Resultado Incorrecto' : ''
          }
        </div> : <Checkbox checked={audit ? true : false} onChange={onChange}>
            Resultado Incorrecto
        </Checkbox>
      }
    </div>
  )
}

export default FeedbackResult;