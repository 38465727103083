import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Row, Col, Card, Spin, List, Button, DatePicker, Form } from 'antd';
import { auditService } from '../_services/audit.service';
import { InfoCircleFilled } from '@ant-design/icons';

import moment from 'moment';


const { RangePicker } = DatePicker;

const FeedbacksPage = () => {
  const startDate = moment().subtract(1, 'week');
  const endDate = moment();

  const [consultations, setConsultations] = useState();
  const [loading, setLoading] = useState(false);
  const [dates, setDates] = useState([startDate, endDate]);

  const getParams = () => {
    const params = {
      startDate: dates[0].format('YYYY-MM-DD'),
      endDate: dates[1].format('YYYY-MM-DD')
    }

    return params;
  }

  const fetchFeedbacks = async () => {
    setLoading(true);
    const response = await auditService.getFeedbacks(getParams());
    setConsultations(response.data);
    setLoading(false);
  }

  useEffect(() => {
    fetchFeedbacks();
  }, [dates])

  const handleChange = (e) => {
    setDates(e);
  }

  const isHeaderConclusionFeedback = (feedback) => {
    return feedback.name != 'detail' && feedback.name != 'comment' && feedback.name != 'conclusion' && feedback.name != 'wrong_result';
  }

  if (loading) {
    return (
      <Spin />
    )
  }

  return (
    <div>
      <Form layout='vertical'>
        <Row>
          <Col>
            <Form.Item label='Fechas' name='dates'>
              <RangePicker style={{ width: '100%', marginBottom: '2em' }}
                size="large"
                value={dates}
                defaultValue={dates}
                onChange={handleChange}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <Row>
        <Col lg={24}>
          {
            consultations && Object.keys(consultations).map(consultationId => {
              return (
                <Card style={{ marginBottom: 40 }} title={
                  <div>
                    <span>
                      <strong> Bateria: </strong>
                      {consultations[consultationId]['header']['battery']}
                    </span>

                    <span style={{ marginLeft: 10 }}>
                      <strong>    Sucursal: </strong>
                      {consultations[consultationId]['header']['branchOffice']}
                    </span>

                    <span style={{ marginLeft: 10 }}>
                      <strong>    Doctor: </strong>
                      {consultations[consultationId]['header']['admin']}
                    </span>
                  </div>
                } extra={[
                  <Link to={`/auditoria/${consultations[consultationId]['header']['audit_session_id']}/${consultations[consultationId]['header']['audit_consultation_id']}/?view=true`}>
                    <Button type="primary">Ver Consulta</Button>
                  </Link>
                ]}>
                  <div>
                    <h2>Observaciones</h2>
                    {
                      consultations[consultationId]['feedbacks'].filter(feedback => feedback.name == 'wrong_result').length > 0 && <div style={{ backgroundColor: '#e74c3c', color: 'white', borderRadius: 5, padding: 20, marginBottom: 30 }}>
                        Resultado Incorrecto
                      </div>
                    }

                    {consultations[consultationId]['feedbacks'].map(feedback => {
                      return (
                        <div style={{ marginBottom: 20 }}>
                          <strong><InfoCircleFilled style={{ marginRight: 5, color: '#5B366D', fontSize: 20 }} />
                            {feedback.name == 'detail' && 'Examen Físico'}
                            {feedback.name == 'comment' && 'Anamnesis'}
                            {feedback.name == 'conclusion' && 'Conclusiones'}
                            {isHeaderConclusionFeedback(feedback) && feedback.name}
                          </strong>
                          {
                            feedback.name != 'wrong_result' && <div style={{ backgroundColor: '#f4f4f4', borderRadius: 5, padding: 20 }}>
                              {feedback.feedback}
                            </div>
                          }
                        </div>
                      )
                    })}
                  </div>
                </Card>
              )
            })
          }
        </Col>
      </Row>
    </div>
  )
}

export default FeedbacksPage;