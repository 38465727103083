import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';

import { PrivateRoute } from '../_components';
import { HomePage } from '../HomePage';
import AuditPage from '../AuditPage/AuditPage';
import FeedbacksPage from '../FeedbacksPage/FeedbacksPage';

function App() {
    return (
        <div className="jumbotron">
            <div className="container">
                <div className="col-md-8 offset-md-2">
                    <Router>
                        <Switch>
                            <PrivateRoute exact path="/auditoria/:auditId/:consultationId" component={AuditPage} />
                            <PrivateRoute exact path="/auditoria/observaciones" component={FeedbacksPage} />
                            <PrivateRoute exact path="/" component={HomePage} />
                            {/* <Redirect from="*" to="/" /> */}
                        </Switch>
                    </Router>
                </div>
            </div>
        </div>
    );
}

export { App };