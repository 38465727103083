import React, { useEffect, useState } from 'react';
import { Card, Table } from 'antd';
import { dashboardService } from '../../_services/dashboard.service';

const columns = [
  {
    title: 'Exámen',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Cantidad',
    dataIndex: 'count',
    key: 'count',
  },
]

const CountByExamReport = ({ branchOffices }) => {
  const [data, setData] = useState([]);

  const fetchData = async () => {
    const response = await dashboardService.getCountByExamReport({});
    setData(response.data);
  }

  useEffect(() => {
    fetchData();
  }, [])

  return (
    <Card style={{ paddingRight: '1em' }} title="Exámenes con observaciones">
      <Table columns={columns} dataSource={data} pagination={false} />
    </Card>
  )
}

export default CountByExamReport;