import config from '../config';
import { authHeader } from '../_helpers';
import handleResponse from './handle_response';


export const auditService = {
  create,
  finish,
  exams,
  consultation,
  getDocumentFile,
  getProcedureResultFile,
  removeFeedback,
  saveFeedback,
  getFeedback,
  getFeedbacks
};

function create(params) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader()
  };

  const url = new URL(`${config.apiUrl}/audit/create`);
  Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
  return fetch(url, requestOptions).then(handleResponse);
}

function finish(params) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader()
  };

  const url = new URL(`${config.apiUrl}/audit/finish`);
  Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
  return fetch(url, requestOptions).then(handleResponse);
}

function consultation(params) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  const url = new URL(`${config.apiUrl}/audit/consultation/`);
  Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
  return fetch(url, requestOptions).then(handleResponse);
}

function exams(params) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  const url = new URL(`${config.apiUrl}/audit/exams/`);
  Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
  return fetch(url, requestOptions).then(handleResponse);
}

function saveFeedback(data) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };

  const url = new URL(`${config.apiUrl}/audit/feedback/`);
  return fetch(url, requestOptions).then(handleResponse);
}

function removeFeedback(data) {
  const requestOptions = {
    method: 'DELETE',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };

  const url = new URL(`${config.apiUrl}/audit/removeFeedback/`);
  return fetch(url, requestOptions).then(handleResponse);
}

function getFeedback(params) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  const url = new URL(`${config.apiUrl}/audit/getFeedback/`);
  Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
  return fetch(url, requestOptions).then(handleResponse);
}

function getFeedbacks(params) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  const url = new URL(`${config.apiUrl}/audit/feedbacks`);
  Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
  return fetch(url, requestOptions).then(handleResponse);
}

function getProcedureResultFile(params) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  const url = new URL(`${config.apiUrl}/audit/getProcedureResultFile`);
  Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
  return fetch(url, requestOptions).then(handleResponse);
}

function getDocumentFile(params) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  const url = new URL(`${config.apiUrl}/audit/getFile`);
  Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
  return fetch(url, requestOptions).then(handleResponse);
}