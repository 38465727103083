import React, { useEffect, useState } from 'react';
import { Card } from 'antd';
import { dashboardService } from '../../_services/dashboard.service';

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';

const ComparativeChart = () => {
  const [data, setData] = useState();

  const fetchData = async () => {
    const response = await dashboardService.getAuditedCountByDates({});
    setData(response.data.map(item => {
      return {
        name: item.name,
        informes_con_observaciones: parseInt(item.informes_con_observaciones),
        informes_sin_observaciones: parseInt(item.informes_sin_observaciones)
      }
    }));
  }

  useEffect(() => {
    fetchData();
  }, [])

  return (
    <Card title="Comparativo de ultimos informes auditados" style={{ marginTop: 40 }}>
      <ResponsiveContainer height={300}>
        <BarChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 20, right: 30, left: 20, bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="informes_con_observaciones" stackId="a" fill="#E78B6A" />
          <Bar dataKey="informes_sin_observaciones" stackId="a" fill="#6FB9A7" />
        </BarChart>
      </ResponsiveContainer>
    </Card>
  )
}

export default ComparativeChart;